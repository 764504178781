import { useState, useEffect } from 'react';

type Props = {
};

const Privacy = (props: Props) => {
    const [text, setText] = useState("");
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('/Home/_Privacy');
            const data = await response.text();
            setText(data);
            setLoadingData(false);
        };
        fetchData();
    }, []);

    return <>{!loadingData ? <div style={{ margin: "30px auto 100px auto", maxWidth: "800px" }} dangerouslySetInnerHTML={{ __html: text }} /> : <p><em>Loading...</em></p>}</>;
}

export default Privacy;
