import { useState } from 'react';
import { 
	Collapse,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	Nav,
	NavItem,
	NavLink 
} from 'reactstrap';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';

interface INavMenu {
    brand: string;
}

const NavMenu = (props: INavMenu) => {
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);

    return (
        <Navbar color="dark" dark expand="md" fixed="top">
          <NavbarBrand href="/">{props.brand}</NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} />
          <Collapse className="justify-content-end" isOpen={!collapsed} navbar>
            <Nav className="navbar-nav flex-grow">
              <NavItem>
                <NavLink href="/#contact" className="text-light">Contact Us</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/privacy" className="text-light">Privacy</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/security" className="text-light">Security</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/termsandconditions" className="text-light">Terms & Conditions</NavLink>
              </NavItem>
              <LoginMenu>
              </LoginMenu>
            </Nav>
          </Collapse>
        </Navbar>
    );
}

export default NavMenu;