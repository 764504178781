import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ContactUs from "./ContactUs";

const Home = () => {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]); // do this on route change

    return (
        <>
            <div id="splash-screen">
            </div>
            <section className="page-section">
                <h2 className="text-center text-uppercase">Welcome to Online Business Pro!</h2>
                <p>Online Business Pro is a comprehensive platform that empowers brands to manage and automate their entire eCommerce operation, encompassing marketplace listings, content, inventory, pricing, orders, fulfillment, and reporting across various supplier, marketplace, and fulfillment networks.

                    We offer pre-built connectors to all major B2C and B2B marketplaces and 3PLs through our proprietary Integration Platform as a Service (iPaaS). This advanced solution can function either as a standalone eCommerce platform or seamlessly integrate with a multitude of ERPs, including but not limited to NetSuite, Microsoft Dynamics, Epicor, and Intuit Quickbooks.

                    By consolidating the complexities of selling on multiple marketplaces, Online Business Pro brings order to the chaos of eCommerce operations, providing automation and enhanced efficiency at every step.</p>
            </section>
            <section className="page-section" id="contact">
                <div className="container px-5">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Contact Us</h2>
                        <h3 className="section-subheading">Interested in our software and services? Please complete the following form.</h3>
                    </div>
                    <ContactUs />
                </div>
            </section>
        </>
    );
}
export default Home;
