import { useId } from 'react';
import { useForm, SubmitHandler, Resolver, FieldErrors } from 'react-hook-form';
import { getClassName, getErrorMessage } from './BootstrapUtils';

export class ContactMessageModel {
	emailAddress: string;
	message: string;
	name: string;
	phoneNumber: string;

	constructor(emailAddress?: string, message?: string, name?: string, phoneNumber?: string) {
		this.emailAddress = emailAddress ?? "";
		this.message = message ?? "";
		this.name = name ?? "";
		this.phoneNumber = phoneNumber ?? "";
	}
}

export const ContactMessageModelResolver: Resolver<ContactMessageModel> = async (values) => {
	const errors: FieldErrors<ContactMessageModel> = {};

	if (values.emailAddress && !/\S+@\S+\.\S+/.test(values.emailAddress)) {
		errors.emailAddress = {
			type: 'pattern',
			message: 'The email address field is not a valid e-mail address.'
		};
	}
	if (!values.emailAddress) {
		errors.emailAddress = {
			type: 'required',
			message: 'Email address is required.'
		};
	}
	if (!values.message) {
		errors.message = {
			type: 'required',
			message: 'Message is required.'
		};
	}
	if (!values.name) {
		errors.name = {
			type: 'required',
			message: 'Name is required.'
		};
	}
	if (values.phoneNumber && !/^(\+1|1)?[ -]?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})[ -]?[2-9][0-9]{2}[ -]?[0-9]{4}$/.test(values.phoneNumber)) {
		errors.phoneNumber = {
			type: 'pattern',
			message: 'The phone number field is not a valid phone number.'
		};
	}
	if (!values.phoneNumber) {
		errors.phoneNumber = {
			type: 'required',
			message: 'Phone number is required.'
		};
	}

	return {
		values,
		errors
	};
};

export type ContactUsFormData = {
	contactMessageModel?: ContactMessageModel,
	onSubmit: SubmitHandler<ContactMessageModel>,
	errorMessage?: string;
	message?: string
};

export const ContactUsForm = (props: ContactUsFormData) => {
	const formId = useId();
	const { register, handleSubmit, formState: { errors, touchedFields, isValid, isSubmitting } } = useForm<ContactMessageModel>({
		mode: "onTouched",
		resolver: ContactMessageModelResolver,
		defaultValues: props.contactMessageModel ?? new ContactMessageModel()
	});

	return <form onSubmit={handleSubmit(props.onSubmit)}>
		<div className="row align-items-stretch mb-3">
			<div className="col-md-6">
				<div className="form-group">
					<input type="text" className={getClassName(touchedFields.name, errors.name)} id={formId + "-name"} {...register("name")} placeholder="Your Name *" />
					{getErrorMessage(errors.name)}
				</div>
				<div className="form-group">
					<input type="email" className={getClassName(touchedFields.emailAddress, errors.emailAddress)} id={formId + "-emailAddress"} {...register("emailAddress")} placeholder="Your Email Address*" />
					{getErrorMessage(errors.emailAddress)}
				</div>
				<div className="form-group mb-md-0">
					<input type="tel" className={getClassName(touchedFields.phoneNumber, errors.phoneNumber)} id={formId + "-phoneNumber"} {...register("phoneNumber")} placeholder="Your Phone #*" />
					{getErrorMessage(errors.phoneNumber)}
				</div>
			</div>
			<div className="col-md-6">
				<div className="form-group form-group-textarea mb-md-0">
					<textarea className={getClassName(touchedFields.message, errors.message)} id={formId + "-message"} {...register("message")} placeholder="Your Message*" />
					{getErrorMessage(errors.message)}
				</div>
			</div>
		</div>
		{ props.message &&
			<div className="row">
				<div className="text-center text-white mb-3">
					<div className="fw-bolder text-success">{props.message}</div>
				</div>
			</div>
		}
		{ props.errorMessage &&
			<div className="row">
				<div className="text-center text-white mb-3">
					<div className="fw-bolder text-danger">{props.errorMessage}</div>
				</div>
			</div>
		}
		<div className="row">
			<div className="form-group col-md-12 text-center">
				<button className="btn btn-primary btn-xl text-uppercase" type="submit" disabled={!!props.message || !isValid || isSubmitting}>Send Message</button>
			</div>
		</div>
	</form>;
};