import { ReactNode } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import './Site.css';

type Props = {
    children: ReactNode;
};

const Layout = (props: Props) => {
    return (
        <Container id="mainLayout">
            <NavMenu brand={ "Online Business Pro" } />
            <Container tag="main" fluid className="px-0">
              {props.children}
            </Container>
            <footer className="sticky-footer">
                <div className="copyright text-center m-auto">
                    <span>Copyright &copy; {(new Date()).getFullYear()} - Programount Inc.</span>
                </div>
            </footer>
        </Container>
    );
}

export default Layout;