import { useState } from 'react';
import { ContactUsForm, ContactMessageModel } from './ContactUsForm'

const ContactUs = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");

    const submitMessage = async (data: ContactMessageModel) => {
        setErrorMessage("");
        setMessage("");
        const headers: Record<string, string> = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        try {
            const response = await fetch('/Home/_ContactUs', {
                method: 'POST',
                headers,
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                setErrorMessage(`Sorry. We are unable to send the message. Please try again later. Network error '${response.statusText}'`);
            } else {
                const responseData = await response.json();
                setMessage(responseData.Message);
            }
        } catch (error) {
            setErrorMessage(`Sorry. We are unable to send the message. Please try again later`);
        }
    };

    return <ContactUsForm onSubmit={submitMessage} message={message} errorMessage={errorMessage} />;
}

export default ContactUs;
