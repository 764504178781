import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import Home from "./components/Home";
import Privacy from "./components/Privacy";
import Security from "./components/Security";
import TermsAndConditions from "./components/TermsAndConditions";

export type AppRoute = {
    index?: boolean;
    element: JSX.Element,
    path?: string,
    requireAuth?: boolean;
};

const AppRoutes: AppRoute[] = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/privacy',
    element: <Privacy />
  },
  {
      path: '/security',
      element: <Security />
  },
  {
      path: '/termsandconditions',
      element: <TermsAndConditions />
  },
  ...ApiAuthorzationRoutes
];

export default AppRoutes;
